import LayoutEffect from "../../LayoutEffect";
import SectionWrapper from "../../SectionWrapper";

const Features = () => {
  const featuresList = [
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path d="M21.750 13.500 L1.500 13.500 L1.500 3.000 C1.500 2.172 2.172 1.500 3.000 1.500 L20.250 1.500 C21.078 1.500 21.750 2.172 21.750 3.000 L21.750 13.500 Z M21.750 16.500 C21.750 17.328 21.078 18.000 20.250 18.000 L3.000 18.000 C2.172 18.000 1.500 17.328 1.500 16.500 L1.500 15.000 L21.750 15.000 Z M10.500 19.500 L13.500 19.500 L13.500 22.500 L10.500 22.500 Z M20.250 0.000 L3.000 0.000 C1.343 0.000 0.000 1.343 0.000 3.000 L0.000 16.500 C0.000 18.157 1.343 19.500 3.000 19.500 L9.000 19.500 L9.000 22.500 L7.500 22.500 C7.085 22.500 6.750 22.836 6.750 23.250 C6.750 23.665 7.085 24.000 7.500 24.000 L16.500 24.000 C16.915 24.000 17.250 23.665 17.250 23.250 C17.250 22.836 16.915 22.500 16.500 22.500 L15.000 22.500 L15.000 19.500 L20.250 19.500 C21.907 19.500 23.250 18.157 23.250 16.500 L23.250 3.000 C23.250 1.343 21.907 0.000 20.250 0.000 Z" />
        </svg>
      ),
      title: "Desktop-Wide Accessibility",
      desc: "Access ChatGPT seamlessly from anywhere on your desktop.",
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path d="M4.800 13.200 H4.812 M4.800 18.000 H4.812 M9.600 13.200 H9.612 M14.400 13.200 H14.412 M19.200 18.000 H19.212 M19.200 13.200 H19.212 M16.800 1.200 V3.600 H7.200 V9.600 M9.600 18.000 H14.400 M3.840 22.800 H20.160 C20.976 22.800 21.504 22.800 21.941 22.690 C22.308 22.538 22.690 22.308 22.890 21.941 C23.141 21.490 23.141 20.976 23.141 20.304 V12.240 C23.141 11.310 23.141 10.896 22.890 10.492 C22.690 10.059 22.308 9.710 21.941 9.492 C21.504 9.262 20.976 9.262 20.160 9.262 H3.840 C3.024 9.262 2.496 9.262 2.059 9.492 C1.692 9.710 1.310 10.059 1.090 10.492 C0.839 10.896 0.839 11.310 0.839 12.240 V20.304 C0.839 21.230 0.839 21.744 1.090 22.176 C1.310 22.538 1.692 22.838 2.059 23.059 C2.496 23.290 3.024 23.290 3.840 23.290 Z" />
        </svg>
      ),
      title: "Intuitive Hotkey Activation",
      desc: "Summon ChatGPT effortlessly with customizable hotkeys.",
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
          />
        </svg>
      ),
      title: "Context-Aware Multitasking",
      desc: "ChatGPT adapts to your tasks and provides relevant assistance.",
    },

    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="w-6 h-6"
        >
          <path d="M1,12 C1,12 5,4 12,4 C19,4 23,12 23,12 C23,12 19,20 12,20 C5,20 1,12 1,12 Z"></path>
          <circle cx="12" cy="12" r="3"></circle>
        </svg>
      ),
      title: "Visual Question Answering",
      desc: "Visual Question Answering is the task of answering open-ended questions based on an image. They output natural language responses to natural language questions.",
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3 7.5h5.4a1.1 1.1 0 011.1 1.1v8a1.1 1.1 0 01-1.1 1.1H3v-10zm18 0h-5.4a1.1 1.1 0 00-1.1 1.1v8a1.1 1.1 0 001.1 1.1H21v-10zm-11 0H9.6a1.1 1.1 0 00-1.1 1.1v8a1.1 1.1 0 001.1 1.1H10v-10z"
          />
        </svg>
      ),
      title: "Seamless Application Integration",
      desc: "ChatGPT effortlessly integrates with your favorite apps, streamlining your workflow and enhancing productivity.",
    },

    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="M13 3H7C5.89543 3 5 3.89543 5 5V10M13 3L19 9M13 3V8C13 8.55228 13.4477 9 14 9H19M19 9V19C19 20.1046 18.1046 21 17 21H10C7.79086 21 6 19.2091 6 17V17C6 14.7909 7.79086 13 10 13H13M13 13L10 10M13 13L10 16"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
      title: "Seamless Updates and Support",
      desc: "Enjoy automatic updates and continuous support.",
    },
  ];

  return (
    <SectionWrapper>
      <div id="features" className="custom-screen text-gray-300">
        <LayoutEffect
          className="duration-1000 delay-300"
          isInviewState={{
            trueState: "opacity-1",
            falseState: "opacity-0 translate-y-6",
          }}
        >
          <div className="max-w-xl mx-auto text-center">
            <h2 className="text-gray-50 text-3xl font-semibold sm:text-4xl">
              Enhance Your Digital Experience with ChatNCL Integration
            </h2>
            <p className="mt-3">
              ChatNCL offers seamless integration capabilities, making it easy
              to connect with your preferred applications. Enhance your
              workflows, boost productivity, and enjoy a more personalized
              interaction by integrating ChatGPT into your digital ecosystem.
            </p>
          </div>
        </LayoutEffect>
        <LayoutEffect
          className="duration-1000 delay-500"
          isInviewState={{
            trueState: "opacity-1",
            falseState: "opacity-0",
          }}
        >
          <div className="relative mt-12">
            <ul className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {featuresList.map((item, idx) => (
                <li
                  key={idx}
                  className="space-y-3 p-4 rounded-xl border border-gray-800"
                  style={{
                    background:
                      "radial-gradient(157.73% 157.73% at 50% -29.9%, rgba(203, 213, 225, 0.16) 0%, rgba(203, 213, 225, 0) 100%)",
                  }}
                >
                  <div className="w-12 h-12 flex items-center justify-center bg-gray-700 rounded-lg text-gray-50">
                    {item.icon}
                  </div>
                  <h3 className="text-lg text-gray-50 font-semibold">
                    {item.title}
                  </h3>
                  <p>{item.desc}</p>
                </li>
              ))}
            </ul>
          </div>
        </LayoutEffect>
      </div>
    </SectionWrapper>
  );
};

export default Features;
