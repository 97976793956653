const GradientWrapper = ({ children, ...props }) => (
  <div {...props} className={`relative ${props.className || ""}`}>
    <div
      className={`absolute m-auto blur-[160px] ${props.wrapperClassName || ""}`}
      style={{
        background:
          "linear-gradient(180deg, #1a73e8 0%, rgb(37, 99, 235, 0.984375) 0.01%, rgba(37, 99, 235, 0.2) 100%)",
      }}
    ></div>
    <div className="relative">{children}</div>
  </div>
);

export default GradientWrapper;
