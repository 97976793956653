import SectionWrapper from "../../SectionWrapper";
import GradientWrapper from "../../GradientWrapper";
// import user1 from "@/public/testimonial/user1.webp";
// import user2 from "@/public/testimonial/user2.webp";
// import user3 from "@/public/testimonial/user3.webp";
// import user4 from "@/public/testimonial/user4.webp";
// import user5 from "@/public/testimonial/user5.webp";
// import user6 from "@/public/testimonial/user6.webp";
// import Image from "next/image";
import LayoutEffect from "../../LayoutEffect";

const Testimonial = () => {
  const testimonials = [
    {
      name: "Alice Johnson",

      quote:
        "ChatNCL has revolutionized the way we interact with AI on our desktops. Its seamless integration and user-friendly interface make it an indispensable tool for our team.",
    },
    {
      name: "David Smith",

      quote:
        "Using ChatNCL has significantly boosted our productivity. The ease of accessing ChatGPT's powerful features directly from our desktops is a game-changer.",
    },
    {
      name: "Sarah Lee",

      quote:
        "ChatNCL's ability to integrate with our existing applications has transformed our workflow. It's intuitive, efficient, and incredibly powerful.",
    },
    {
      name: "Ahmed Malik",

      quote:
        "I've been impressed with ChatNCL's performance and versatility. It's not just a tool; it's like having a smart assistant by your side at all times.",
    },
    {
      name: "Ibrahim Khan",

      quote:
        "ChatNCL is the best AI integration we've implemented. It adapts to our needs, learns our preferences, and has become essential in our daily operations.",
    },
    {
      name: "John Doe",

      quote:
        "ChatNCL offers a futuristic approach to AI on the desktop. Its customization and cross-platform synchronization capabilities are unmatched.",
    },
  ];

  return (
    <SectionWrapper>
      <div id="testimonials" className="custom-screen text-gray-300">
        <div className="max-w-2xl text-center md:mx-auto">
          <h2 className="text-gray-50 text-3xl font-semibold sm:text-4xl">
            ChatNCL is loved by Users around the world
          </h2>
        </div>
        <GradientWrapper
          wrapperClassName="max-w-sm h-40 top-12 inset-x-0"
          className="mt-12"
        >
          <LayoutEffect
            className="duration-1000 delay-300"
            isInviewState={{
              trueState: "opacity-1",
              falseState: "opacity-0 translate-y-12",
            }}
          >
            <ul className="grid gap-6 duration-1000 delay-300 ease-in-out sm:grid-cols-2 lg:grid-cols-3">
              {testimonials.map((item, idx) => (
                <li
                  key={idx}
                  className="p-4 rounded-xl border border-gray-800"
                  style={{
                    backgroundImage:
                      "radial-gradient(100% 100% at 50% 50%, rgba(124, 58, 237, 0.05) 0%, rgba(124, 58, 237, 0) 100%)",
                  }}
                >
                  <figure className="flex flex-col justify-between gap-y-6 h-full">
                    <blockquote className="">
                      <p className="">{item.quote}</p>
                    </blockquote>
                    <div className="flex items-center gap-x-4">
                      <div>
                        <span className="block text-gray-50 font-semibold">
                          {item.name}
                        </span>
                      </div>
                    </div>
                  </figure>
                </li>
              ))}
            </ul>
          </LayoutEffect>
        </GradientWrapper>
      </div>
    </SectionWrapper>
  );
};

export default Testimonial;
