const Brand = ({ ...props }) => (
  <img
    src="/nclwings.svg"
    alt="NCL logo"
    {...props}
    width={110}
    height={40}
    priority
  />
);
export default Brand;
