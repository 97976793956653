import LayoutEffect from "../../LayoutEffect";
import SectionWrapper from "../../SectionWrapper";
import Button from "../Button";
import { useEffect, useState } from "react";
import api from "../../../api";
import { useAuth } from "@clerk/clerk-react";

const Pricing = () => {
  const [selectedGPTVersion, setSelectedGPTVersion] = useState("gpt3");
  const [productTiers, setProductTiers] = useState(null);
  const { getToken } = useAuth();

  useEffect(() => {
    fetchData();
  }, []);

  const filteredPlans = productTiers?.filter(
    (plan) =>
      plan.section === selectedGPTVersion ||
      (selectedGPTVersion === "gpt4" && plan.section === "gpt4Vision")
  );

  const fetchData = async () => {
    try {
      api
        .get("user/product-tiers")
        .then((res) => setProductTiers(res.data.message));
    } catch (error) {}
  };

  const checkout = async (priceID) => {
    const token = await getToken();

    api
      .post("user/create-payment-intent", {
        items: priceID,
        token,
      })
      .then((res) => {
        if (res.data.redirect) window.location.href = "/sign-up";
        window.location.href = res.data.message.url;
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  return (
    <SectionWrapper id="pricing" className="custom-screen">
      <div className="relative max-w-screen-xl mx-auto text-center py-5">
        <div
          className="absolute inset-0 blur-[118px] max-w-lg h-[800px] mx-auto sm:max-w-3xl sm:h-[450px]"
          style={{
            background:
              "linear-gradient(106.89deg, rgba(192, 132, 252, 0.11) 15.73%, rgba(14, 165, 233, 0.41) 15.74%, rgba(232, 121, 249, 0.26) 56.49%, rgba(79, 70, 229, 0.4) 115.91%)",
          }}
        ></div>
        <div className="relative max-w-screen-2xl mx-auto text-gray-300 sm:px-4 md:px-8">
          <div className="relative max-w-xl mx-auto text-center">
            <h2 className="text-gray-50 text-3xl font-semibold sm:text-4xl">
              Find a plan to power your Creativity
            </h2>
            <div className="flex justify-center gap-4 mt-6">
              <button
                className={`px-4 py-2 rounded ${
                  selectedGPTVersion === "gpt3"
                    ? "bg-blue-600 text-white"
                    : "text-white "
                }`}
                onClick={() => setSelectedGPTVersion("gpt3")}
              >
                GPT-3 Plans
              </button>
              <button
                className={`px-4 py-2 rounded ${
                  selectedGPTVersion === "gpt4"
                    ? "bg-blue-600 text-white"
                    : "text-white"
                }`}
                onClick={() => setSelectedGPTVersion("gpt4")}
              >
                GPT-4 Plans
              </button>
              <button
                className={`px-4 py-2 rounded ${
                  selectedGPTVersion === "Custom"
                    ? "bg-blue-600 text-white"
                    : "text-white"
                }`}
                onClick={() => setSelectedGPTVersion("Custom")}
              >
                Custom
              </button>
            </div>
          </div>
          <LayoutEffect
            className="duration-1000 delay-300"
            isInviewState={{
              trueState: "opacity-1",
              falseState: "opacity-0",
            }}
          >
            <div className="mt-16 justify-center gap-6 sm:grid sm:grid-cols-2 sm:space-y-0 lg:grid-cols-3">
              {filteredPlans?.map((item, idx) => (
                <div
                  key={idx}
                  className={`relative flex-1 flex items-stretch flex-col mt-6 border-2 sm:mt-0 sm:rounded-xl sm:max-w-md ${
                    item.isMostPop
                      ? "bg-gray-900 border-blue-400 border-x-2 rounded-xl"
                      : "border-transparent"
                  }`}
                >
                  <div className="p-4 py-8 space-y-4 border-b border-gray-700 md:p-8">
                    <span className="text-gray-200 font-medium">
                      {item.title}
                    </span>
                    <div className="text-blue-400 text-3xl font-semibold">
                      ${item.price}
                    </div>
                    <p className="text-gray-400">{item.desc}</p>
                    <Button
                      className="px-3 py-3 rounded-lg w-full font-semibold text-sm duration-150 text-white bg-blue-500 hover:bg-blue-700 active:bg-blue-400"
                      onClick={() => checkout(item.priceID)}
                    >
                      Get Started
                    </Button>
                  </div>
                  <ul className="p-4 py-8 space-y-3 md:p-8">
                    {item.description.map((featureItem, idx) => (
                      <li key={idx} className="flex items-center gap-5">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className={`h-5 w-5 ${
                            item.isMostPop ? "text-blue-600" : ""
                          }`}
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        {featureItem}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </LayoutEffect>
        </div>
      </div>
    </SectionWrapper>
  );
};

export default Pricing;
