import React from "react";
import {
  Typography,
  Box,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import VpnKeyIcon from "@mui/icons-material/VpnKey"; // Example icon for keys
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // Example icon for status
import ErrorIcon from "@mui/icons-material/Error";
import KeyField from "../../components/KeyField";
import TimerDisplay from "../../components/timerDisplay";

const CustomProfilePage = ({ userData }) => {
  return (
    <Paper
      sx={{
        backgroundColor: "#19191a",
        borderRadius: 2,
        boxShadow: 1,
        overflow: "auto",
      }}
    >
      <Box sx={{ padding: 3 }}>
        <Typography
          variant="h4"
          sx={{ marginBottom: 2, fontWeight: "bold", color: "#fff" }}
        >
          User Keys Information
        </Typography>

        <List>
          {userData &&
            Object.entries(userData.keyAuth).map(([model, details]) => {
              return (
                <React.Fragment key={model}>
                  <Divider sx={{ backgroundColor: "#ffffff3b" }} />
                  <Box sx={{ mt: 2 }}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: "white",
                        fontSize: 20,
                        fontFamily: "NeologyGrotesque",
                        ml: 1,
                      }}
                    >
                      {details.model}
                    </Typography>
                    <ListItem>
                      <ListItemIcon>
                        <VpnKeyIcon sx={{ color: "#fff", fontSize: 20 }} />
                      </ListItemIcon>
                      <ListItemText
                        secondary={
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              flexGrow: 10,
                            }}
                          >
                            <KeyField
                              label={details.model}
                              value={details.key}
                            />
                          </Box>
                        }
                      />
                    </ListItem>
                    <TimerDisplay timerSeconds={details.timer} />
                    <ListItem>
                      <ListItemIcon>
                        {details.status ? (
                          <CheckCircleIcon
                            sx={{
                              color: "success.main",
                              fontSize: 20,
                            }}
                          />
                        ) : (
                          <ErrorIcon
                            sx={{ color: "error.main", fontSize: 20 }}
                          />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary="Status"
                        secondary={details.status ? "Inactive" : "Active"}
                        sx={{
                          color: "#fff",
                          "& .MuiListItemText-secondary": { color: "#fff" },
                        }}
                      />
                    </ListItem>
                  </Box>
                </React.Fragment>
              );
            })}
        </List>
      </Box>
    </Paper>
  );
};

export default CustomProfilePage;
