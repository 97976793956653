import Footer from "./ui/Footer";
import Navbar from "./ui/Navbar";
import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <>
      <body className="bg-gray-900 h-full">
        <Navbar />
        <main>
          <Outlet />
        </main>
        <Footer />
      </body>
    </>
  );
};

export default Layout;
