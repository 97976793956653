import React from "react";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
} from "@mui/material";
import TimerIcon from "@mui/icons-material/Timer";

// Helper function to convert seconds to HH:MM:SS format
const formatTime = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  const paddedHours = hours.toString().padStart(2, "0");
  const paddedMinutes = minutes.toString().padStart(2, "0");
  const paddedSeconds = seconds.toString().padStart(2, "0");

  return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
};

const TimerDisplay = ({ timerSeconds }) => {
  const timeLeft = formatTime(timerSeconds);

  return (
    <ListItem>
      <ListItemIcon>
        <TimerIcon sx={{ color: "#fff", fontSize: 20 }} />
      </ListItemIcon>
      <ListItemText
        primary="Time Left on Key"
        secondary={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" sx={{ color: "#41a4e1", mr: 2 }}>
              {timeLeft}
            </Typography>
            <Typography variant="caption" sx={{ color: "#fff" }}>
              (hours : minutes : seconds)
            </Typography>
          </Box>
        }
        sx={{
          color: "#fff",
          "& .MuiListItemText-secondary": { color: "#fff" },
        }}
      />
    </ListItem>
  );
};

// Example usage:
// <TimerDisplay timerSeconds={details.timer} />

export default TimerDisplay;
