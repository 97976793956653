import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
});

api.interceptors.response.use(
  (response) => {
    // Any status code that lies within the range of 2xx will cause this function to trigger
    return response;
  },

  (error) => {
    if (error.response) {
      if (error.response.status === 404) {
        window.location.href = "/PageNotFound";
      } else if (error.response.status === 500) {
        window.location.href = "/InternalServerError";
      }
    }
    return Promise.reject(error);
  }
);

export default api;
