import React from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import GradientWrapper from "../../components/GradientWrapper";
import Button from "../../components/ui/Button";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <GradientWrapper wrapperClassName="max-w-xs h-[13rem] top-12 inset-0">
      <section
        className="flex justify-center items-center flex-col"
        style={{ height: "70vh" }}
      >
        {/* Logo placeholder, you can remove this if you don't need it */}
        <Box
          component={"img"}
          src={"images/404 erro.svg"}
          sx={{ height: "50%" }}
        />
        <h1 className="text-white mt-3">
          404 | The page you're looking for doesn't exist.
        </h1>
        <Button
          onClick={() => navigate("/")}
          className={
            "rounded-full text-white bg-blue-600 hover:bg-blue-500 mt-3 "
          }
        >
          Go Back to Home
        </Button>
      </section>
    </GradientWrapper>
  );
};

export default PageNotFound;
