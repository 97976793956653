import React, { useState } from "react";
import { Box, IconButton, InputBase, Paper } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useSnackbar } from "notistack";
import copy from "copy-to-clipboard";

const KeyField = ({ label, value }) => {
  const [showKey, setShowKey] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleCopy = () => {
    copy(value);
    enqueueSnackbar("Key copied to clipboard", { variant: "success" });
  };

  const toggleVisibility = () => {
    setShowKey(!showKey);
  };

  return (
    <Paper
      component="div"
      sx={{
        display: "flex",
        alignItems: "center",
        color: "white",
        backgroundColor: "#333", // Adjust color to match your theme
        padding: "2px 4px",
        margin: "8px 0",
        borderRadius: 2,
        width: 350,
      }}
    >
      <Box sx={{ ml: 1, flex: 1 }}>
        <InputBase
          sx={{ color: "white", ml: 1, flex: 1 }}
          value={showKey ? value : "••••••••••••••••••••"}
          inputProps={{ "aria-label": label, readOnly: true }}
        />
      </Box>
      <IconButton
        sx={{ p: "10px", color: "white" }}
        aria-label="Copy"
        onClick={handleCopy}
      >
        <ContentCopyIcon sx={{ fontSize: 20 }} />
      </IconButton>
      <IconButton
        sx={{ p: "10px", color: "white", fontSize: 20 }}
        aria-label="Toggle visibility"
        onClick={toggleVisibility}
      >
        {showKey ? <VisibilityOffIcon /> : <VisibilityIcon />}
      </IconButton>
    </Paper>
  );
};

export default KeyField;
