import React, { useEffect, useState } from "react";
import { Container, Typography, TextField, Button, Box } from "@mui/material";
import api from "../../api";
import { useUser } from "@clerk/clerk-react";
import { useSnackbar } from "notistack";
import GradientWrapper from "../../components/GradientWrapper";

const ContactUs = () => {
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (nameInputValue, emailInputValue, messageInputValue) => {
    const formData = {
      name: nameInputValue,
      email: emailInputValue,
      message: messageInputValue,
    };
    if (
      (nameInputValue !== "") &
      (emailInputValue !== "") &
      (messageInputValue !== "")
    ) {
      api
        .post(`user/contact`, formData)
        .then((res) => {
          enqueueSnackbar(res.data.message, { variant: "success" });
          setInterval(() => {
            window.location.href = "/";
          }, 5000);
        })
        .catch((res) => {
          enqueueSnackbar("Sorry something happened. Please try again!", {
            variant: "error",
          });
        });
    } else {
      enqueueSnackbar("Sorry but you can't leave the fields empty", {
        variant: "error",
      });
    }
  };

  const { isSignedIn, user } = useUser();
  const [nameInputValue, setNameInputValue] = useState("");
  const [emailInputValue, setEmailInputValue] = useState("");
  const [messageInputValue, setMessageInputValue] = useState("");

  useEffect(() => {
    if (isSignedIn) {
      setNameInputValue(user.firstName);
      setEmailInputValue(user.emailAddresses[0].emailAddress);
    }
  }, [user, isSignedIn]);

  return (
    <GradientWrapper
      className="mt-20 sm:mt-28 "
      wrapperClassName="max-w-3xl h-[250px] top-12 inset-0 sm:h-[300px] lg:h-[650px]"
    >
      <Container maxWidth="sm" className="h-full">
        <Typography
          variant="h1"
          align="center"
          sx={{
            color: "#1a73e8",
            marginBottom: 3,
          }}
        >
          Contact Us/Feedback
        </Typography>
        <Typography
          variant="h5"
          align="center"
          sx={{
            marginBottom: 3,
            color: "#ddd",
          }}
        >
          Have questions or feedback? Reach out to us!
        </Typography>

        <form
          noValidate
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(nameInputValue, emailInputValue, messageInputValue);
          }}
        >
          {isSignedIn ? (
            <></>
          ) : (
            <>
              <TextField
                fullWidth
                margin="normal"
                label="Name"
                variant="outlined"
                value={nameInputValue}
                onChange={(e) => setNameInputValue(e.target.value)}
                sx={{ background: "rgba(255,255,255,0.1)" }}
                InputProps={{ style: { color: "#ddd" } }}
                InputLabelProps={{ style: { color: "#ddd" } }}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Email"
                variant="outlined"
                value={emailInputValue}
                onChange={(e) => setEmailInputValue(e.target.value)}
                sx={{ background: "rgba(255,255,255,0.1)" }}
                InputProps={{ style: { color: "#ddd" } }}
                InputLabelProps={{ style: { color: "#ddd" } }}
              />
            </>
          )}

          <TextField
            fullWidth
            margin="normal"
            label="Message"
            variant="outlined"
            multiline
            rows={4}
            value={messageInputValue}
            onChange={(e) => setMessageInputValue(e.target.value)}
            sx={{ background: "rgba(255,255,255,0.1)" }}
            InputProps={{ style: { color: "#ddd" } }}
            InputLabelProps={{ style: { color: "#ddd" } }}
          />
          <Box mt={2} sx={{ display: "flex", gap: 3, alignItems: "center" }}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() =>
                handleSubmit(nameInputValue, emailInputValue, messageInputValue)
              }
              sx={{
                height: 50,
                borderRadius: "8px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              Submit
            </Button>
            <Typography variant="body1" align="center" sx={{ color: "orange" }}>
              OR
            </Typography>
            <Button
              href="https://api.whatsapp.com/send?phone=60179403972"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                height: 50,
                width: 50,
                minWidth: 50,
                borderRadius: "50%",
                p: 0,
              }}
            >
              <Box
                component="img"
                src="/whatsapp.png"
                alt="WhatsApp"
                sx={{
                  height: "100%", // Adjust icon size within button
                  width: "100%",
                }}
              />
            </Button>
          </Box>
        </form>
      </Container>
    </GradientWrapper>
  );
};

export default ContactUs;
