import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Layout from "./components/Layout";
import "./index.css";
import { ClerkProvider, SignIn, SignUp } from "@clerk/clerk-react";
import { dark } from "@clerk/themes";
import Authenticate from "./Auth";
import Profile from "./pages/Profile";
import Guide from "./pages/Guide";
import ContactUs from "./pages/ContactUs";
import GradientWrapper from "./components/GradientWrapper";
import PageNotFound from "./pages/Error/pageNotFound";
import InternalServerErrorPage from "./pages/Error/InternalServerErrorPage";

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

function App() {
  return (
    <>
      <BrowserRouter>
        <ClerkProvider
          appearance={{
            baseTheme: dark,
            variables: {
              colorPrimary: "#42a3e2",
              colorText: "white",
            },
          }}
          publishableKey={clerkPubKey}
        >
          <Routes>
            <Route element={<Layout />}>
              <Route path="/" element={<Home />} />
              <Route
                path="/sign-in/*"
                element={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <GradientWrapper
                      className="mt-10 sm:mt-28 "
                      wrapperClassName="max-w-3xl h-[250px] top-12 inset-0 sm:h-[300px] lg:h-[650px]"
                    >
                      <SignIn
                        routing="path"
                        path="/sign-in"
                        signUpUrl="/sign-up"
                        redirectUrl="/authenticate"
                      />
                    </GradientWrapper>
                  </div>
                }
              />
              <Route
                path="/sign-up/*"
                element={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <GradientWrapper
                      className="mt-10 sm:mt-18 "
                      wrapperClassName="max-w-3xl h-[250px] top-12 inset-0 sm:h-[300px] lg:h-[650px]"
                    >
                      <SignUp
                        routing="path"
                        path="/sign-up"
                        signInUrl="/sign-in"
                        redirectUrl="/authenticate"
                      />
                    </GradientWrapper>
                  </div>
                }
              />
              <Route path="/authenticate" element={<Authenticate />} />
              <Route path="/user-profile/*" element={<Profile />} />
              <Route path="/dashboard/:stripeSessionId" element={<Profile />} />
              <Route path="/guide" element={<Guide />} />
              <Route path="/contact" element={<ContactUs />} />
              {/* Catch-all route */}
              <Route
                path="/InternalServerError"
                element={<InternalServerErrorPage />}
              />
              <Route path="*" element={<PageNotFound />} />
            </Route>
          </Routes>
        </ClerkProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
