import { UserProfile, useAuth, useUser } from "@clerk/clerk-react";
import { Box, Chip, Divider, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import api from "../../api";
import CustomProfilePage from "./CustomerProfile";
import ApiIcon from "@mui/icons-material/Api";
import { useParams } from "react-router-dom";
import DiscountIcon from "@mui/icons-material/Discount";
import { useSnackbar } from "notistack";
import copy from "copy-to-clipboard";
import GradientWrapper from "../../components/GradientWrapper";

const Profile = () => {
  const { isSignedIn } = useUser();
  const { sessionId, getToken } = useAuth();
  const [userData, setUserData] = useState();
  const stripeSessionId = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const handleCopy = (value) => {
    copy(value);
    enqueueSnackbar("Key copied to clipboard", { variant: "success" });
  };

  useEffect(() => {
    async function token() {
      const token = await getToken();
      if (isSignedIn && sessionId) {
        api
          .get("/user/user-profile", {
            params: { sessionId, Authorization: `${token}` },
          })
          .then((res) => setUserData(res.data.userData));
        api.post("/user/stripe-session-details", {
          stripeSessionId,
          sessionId,
          Authorization: token,
        });
      }
    }
    token();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignedIn, getToken]);

  return (
    <GradientWrapper
      className="mt-5 sm:mt-8"
      wrapperClassName="max-w-3xl h-[250px] top-12 inset-0 sm:h-[300px] lg:h-[650px]"
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <UserProfile>
          <UserProfile.Page label="Keys" url="keys" labelIcon={<ApiIcon />}>
            <CustomProfilePage userData={userData} />
          </UserProfile.Page>
          <UserProfile.Page
            label="Promo"
            url="promo"
            labelIcon={<DiscountIcon />}
          >
            <Paper
              sx={{
                backgroundColor: "#19191a",
                borderRadius: 2,
                boxShadow: 3,
                padding: 3,
                height: "70vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h4"
                sx={{ fontWeight: "bold", color: "#fff", textAlign: "center" }}
              >
                Your Promo Information
              </Typography>

              <Divider
                sx={{ backgroundColor: "#ffffff3b", my: 3, width: "80%" }}
              />

              {userData && userData.promoCode ? (
                <Box sx={{ textAlign: "center", mb: 2 }}>
                  <Chip
                    label={`Promo Code: ${userData.promoCode}`}
                    color="secondary"
                    sx={{ fontSize: "1.2rem", mb: 2 }}
                    onClick={() => handleCopy(userData.promoCode)}
                  />
                  <Typography variant="body1" sx={{ color: "#fff" }}>
                    Share this promo code with your friends! You get an extra 10
                    minutes added to your timer, and your friend gets a 10%
                    discount on their purchase.
                  </Typography>
                </Box>
              ) : (
                <Typography
                  variant="body1"
                  sx={{ color: "#fff", textAlign: "center" }}
                >
                  You don't have a promo code at the moment.
                </Typography>
              )}
            </Paper>
          </UserProfile.Page>
          <UserProfile.Page label="security" />
        </UserProfile>
      </Box>
    </GradientWrapper>
  );
};

export default Profile;
