import React, { useEffect } from "react";
import api from "../api";
import Loader from "../components/Animation/Loader";
import { useUser, useAuth } from "@clerk/clerk-react";
import { Box } from "@mui/material";

const Authenticate = () => {
  const { isSignedIn } = useUser();
  const { sessionId, getToken } = useAuth();

  useEffect(() => {
    async function token() {
      if (isSignedIn && sessionId) {
        api
          .post(
            "/user/authenticate",

            { sessionId, Authorization: `${await getToken()}` }
          )
          .then((response) => {
            console.log("User data stored:", response.data);
            setInterval(() => (window.location.href = "/"), 2500);
          })
          .catch((error) => {
            console.error("Error storing user data:", error);
          });
      }
    }
    token();
  }, [isSignedIn, sessionId, getToken]);
  return (
    <Box
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loader />
    </Box>
  );
};

export default Authenticate;
