import React from "react";
import ReactPlayer from "react-player";
import {
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  Paper,
  Grid,
} from "@mui/material";
import { KeyboardArrowRight } from "@mui/icons-material";
import GradientWrapper from "../../components/GradientWrapper";

const buttonFunctionality = [
  {
    label: "Send to ChatGPT",
    description: "Submits your question to ChatGPT for an answer.",
    key: "&",
  },
  {
    label: "Print Response",
    description:
      "Once you see a token number, press this to display ChatGPT's reply.",
    key: "Right Shift",
  },
  {
    label: "Clear Conversation",
    description:
      "Wipes the current conversation to start a new converstation with ChatGPT.",
    key: "Delete",
  },
  {
    label: "Clear Typed Text",
    description:
      "Erases the text you've currently entered. Press it before start writing a new question",
    key: "Pause Key",
  },
  {
    label: "ScreenShot for GPT Vision",
    description:
      "Captures a screenshot of your current screen, allowing you to submit it for analysis and response by GPT Vision.",
    key: "~",
  },
  {
    label: "Exit",
    description: "Hold this for 3 seconds to shut down the application.",
    key: "Esc",
    specialNote:
      "Remember to exit after using to ensure the authentication key is reusable for the remaining time",
  },
];

const Guide = () => {
  return (
    <GradientWrapper wrapperClassName="max-w-3xl h-[250px] top-12 inset-0 sm:h-[300px] lg:h-[650px]">
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box
            mt={4}
            elevation={3}
            sx={{
              bgcolor: "rgba(2, 29, 49, 0.5)",
              color: "#ddd",
              padding: 3,
              borderRadius: 2,
              marginX: { xs: 2, md: 5 },
              overflow: "hidden",
            }}
          >
            <Typography
              variant="h4"
              align="center"
              sx={{
                marginBottom: 2,
                color: "#5fb9ff",
                fontFamily: "NeologyGrotesque",
              }}
            >
              How the Program Works
            </Typography>

            <Box
              sx={{
                position: "relative",
                paddingTop: "56.25%", // 16:9 aspect ratio
              }}
            >
              <ReactPlayer
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
                width="100%"
                height="100%"
                url="https://youtu.be/6qmpheTdshE"
                controls={true}
              />
            </Box>

            <Typography
              variant="body2"
              align="center"
              sx={{ marginTop: 2, color: "orange" }}
            >
              Watch the video above for a quick demonstration.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              bgcolor: "rgba(2, 29, 49, 0.50)",
              color: "#ddd",
              padding: 3,
              borderRadius: 2,
              marginX: { xs: 1, md: 5 },
              overflow: "hidden",
            }}
          >
            <Typography
              variant="h4"
              align="center"
              sx={{
                marginBottom: 2,
                color: "#5fb9ff",
                fontFamily: "NeologyGrotesque",
              }}
            >
              Key Configuration Guide
            </Typography>
            <Divider sx={{ marginBottom: 2, background: "#5fb9ff" }} />
            <Box mb={2} sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="body4"
                sx={{
                  textAlign: "center",
                  color: "red",
                  fontWeight: "bold",
                  fontFamily: "NeologyGrotesque",
                }}
              >
                Note: Please make sure you run the application as Administrator
                for better compatibility with other applications.
              </Typography>
              <Typography
                variant="body4"
                sx={{
                  textAlign: "center",
                  color: "orange",
                  fontWeight: "bold",
                  fontFamily: "NeologyGrotesque",
                }}
              >
                Disclaimer: It's recommended to use special keys (e.g., Delete,
                Esc, Caps) for these functionalities. You also need to allow the
                application on windows defender, or any other anti-virus
                software.
              </Typography>
            </Box>
            <List>
              {buttonFunctionality.map((item, index) => (
                <ListItem
                  key={index}
                  sx={{ alignItems: "flex-start", paddingBottom: 2 }}
                  divider={index !== buttonFunctionality.length - 1}
                >
                  <ListItemIcon>
                    <KeyboardArrowRight
                      sx={{ color: "#5fb9ff", fontFamily: "NeologyGrotesque" }}
                    />
                  </ListItemIcon>
                  <Grid container spacing={2}>
                    <Grid item xs={8} md={9}>
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#5fb9ff",
                          fontFamily: "NeologyGrotesque",
                          fontWeight: "bold",
                        }}
                      >
                        {item.label}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ color: "white", fontFamily: "NeologyGrotesque" }}
                      >
                        {item.description}
                        {item.specialNote && (
                          <Typography color="red">
                            {item.specialNote}
                          </Typography>
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={3}>
                      <Typography
                        variant="caption"
                        sx={{
                          color: "white",
                          fontFamily: "NeologyGrotesque",
                          fontSize: "1.1rem",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#5fb9ff",
                            fontFamily: "NeologyGrotesque",
                            fontSize: "1.1rem",
                          }}
                        >
                          Recommended
                        </Typography>{" "}
                        {item.key}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
            </List>
            <Box mt={2}>
              <Typography variant="body2" align="center" color="orange">
                Remember, you can change these key configurations as you please.
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </GradientWrapper>
  );
};

export default Guide;
