import { useEffect, useRef, useState } from "react";
import NavHeader from "../NavHeader";
import NavLink from "../NavLink";
import { UserButton, useAuth } from "@clerk/clerk-react";
import { Button } from "@mui/material";

const Navbar = () => {
  const { isSignedIn } = useAuth();
  const [state, setState] = useState(false);
  const menuBtnEl = useRef();

  const navigation = [
    { name: "Features", href: "/#features" },
    { name: "Pricing", href: "/#pricing" },
    { name: "Testimonials", href: "/#testimonials" },
    { name: "FAQs", href: "/#faqs" },
    { name: "Guide", href: "/guide" },
    { name: "Contact us", href: "/contact" },
  ];

  useEffect(() => {
    document.onclick = (e) => {
      const target = e.target;
      if (!menuBtnEl.current.contains(target)) setState(false);
    };
  }, []);

  return (
    <header className="relative">
      <div className="custom-screen md:hidden">
        <NavHeader
          menuBtnEl={menuBtnEl}
          state={state}
          onClick={() => setState(!state)}
          isSignedIn={isSignedIn}
        />
      </div>
      <nav
        className={`pb-1 md:text-sm md:static md:block ${
          state
            ? "bg-gray-900 relative z-0 top-0 inset-x-0 inset-y-0 rounded-b-2xl shadow-xl md:bg-gray-900" // Increased z-index to 40
            : "hidden"
        }`}
      >
        <div className="custom-screen items-center md:flex">
          {state ? (
            <></>
          ) : (
            <NavHeader
              state={state}
              onClick={() => setState(!state)}
              isSignedIn={isSignedIn}
            />
          )}

          <div
            className={`flex-1 items-center mt-8 text-gray-300 md:font-medium md:mt-0 md:flex ${
              state ? "block" : "hidden"
            } `}
          >
            <ul className="flex-1 justify-center items-center space-y-6 md:flex md:space-x-6 md:space-y-0">
              {navigation.map((item, idx) => {
                return (
                  <li key={idx} className="hover:text-blue-500">
                    <a href={item.href} className="block">
                      {item.name}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          {isSignedIn ? (
            <div className="flex justify-center items-center">
              <Button
                className="hover:text-blue-500 "
                href={process.env.REACT_APP_DOWNLOAD}
                download
                sx={{
                  mr: 3,
                  color: "white",
                }}
              >
                Download
              </Button>
              {state ? (
                <></>
              ) : (
                <div className="">
                  <UserButton
                    userProfileMode="navigation"
                    userProfileUrl="/user-profile"
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="gap-x-6 items-center justify-end mt-6 space-y-6 md:flex md:space-y-0 md:mt-0">
              <a
                href="/sign-in"
                className="block hover:text-blue-500 text-white"
              >
                Sign in
              </a>
              <NavLink
                href="/sign-up"
                className="flex items-center justify-center gap-x-1 text-sm text-white font-medium custom-btn-bg border border-gray-500 active:bg-gray-900 md:inline-flex"
              >
                Start now
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                    clipRule="evenodd"
                  />
                </svg>
              </NavLink>
            </div>
          )}
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
